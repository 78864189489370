import React from 'react'
import NavBar from '../components/Navigation/Navbar'
import Seo from '../components/seo'

import "../styles/App.scss";
import Logosit from '../assets/logo192sitwt.png'

export default function kontakt() {

    const schema = {
        "@context": "http://schema.org",
        "@id": "https://www.sit-solutions.ch#kontakt",
        "@type": "LocalBusiness",
        "alternateName": "SIT",
        "email": "hello@sit-solutions.ch",
        "name" : "SIT Solutions",
        "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "closes": "17:30:00",
              "dayOfWeek": ["Monday"],
              "opens": "08:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "17:30:00",
              "dayOfWeek": ["Tuesday"],
              "opens": "08:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "17:30:00",
              "dayOfWeek": ["Wednesday"],
              "opens": "08:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "17:30:00",
              "dayOfWeek": ["Thursday"],
              "opens": "08:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "17:30:00",
              "dayOfWeek": ["Friday"],
              "opens": "08:00:00"
            }
          ],
        "telephone": "+41445852283",
        "url": "https://www.sit-solutions.ch",
        "image": "https://www.sit-solutions.ch/assets/sit-logo-webdesign-onlineshop-marketing-og.png"
    }

    return (
        <>
        <Seo 
            title="Kontakt & Support"
            description="Auf der Suche nach einer ausgezeichneten Webagentur? Du hast sie soeben gefunden! Lass dich kostenlos beraten."
            schemaMarkup={schema}
        />
        <NavBar className="navbar"/>
        <div className="kontakt-container">
            <div className="kontakt-welcome-container" style={{backgroundImage:`url(${Logosit})`}}>
                <h1>Kontakt & Support</h1>
                <h2>Sag Hallo! Wir freuen uns von Dir zu hören.</h2>
            </div>
            
                <div className="kontakt-text-container">
                    <div className="kontakt-text">
                        <h3>E-Mail</h3>
                        <h4><a href="mailto:hello@sit-solutions.ch">hello@sit-solutions.ch</a></h4>
                        
                    </div>
                    <div className="kontakt-text">
                        <h3>Support</h3>
                        <h4><a href="mailto:support@sit-solutions.ch">support@sit-solutions.ch</a></h4>
                    </div>
                    <div className="kontakt-text">
                        <h3>Telefon</h3>
                        <h4><a href="tel:+41445852283">0445852283</a></h4>
                    </div>
                </div>
            
        </div>
        </>
    )
}
